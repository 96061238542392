import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@core/environments/environment';
import { Pagination } from '@core/models/pagination.model';
import { finalize, map, Observable, tap } from 'rxjs';
import {
  BeneficiaryDetails,
  CashToWalletResponse,
  ProvidusSettlementAcctBalResponse,
  TransactionDetailsResponse,
  TransactionsResponse,
  WalletTransactionDetailsResponse,
  WalletTransactionResponse,
} from '@core/models/admin/account';
import { LoadingService } from './loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import { OptimusSettlementAcctBalResponse } from '@core/models/admin/account/get-optimus-settlement.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  http = inject(HttpClient);
  ADMIN_BASE_URL = environment.ADMIN_BASE_URL;
  BASE_URL = environment.ADMIN_BASE_URL + '/v1/bank';
  WITHDRAWAL_URL = environment.ADMIN_BASE_URL + '/v1/withdrawal';
  PORTFOLIO_URL = environment.ADMIN_BASE_URL + '/v1/portfolio';
  ACCOUNT_URL = environment.ADMIN_BASE_URL + '/v1/account';

  private loadingService = inject(LoadingService);

  transLoadState = {
    getCashWithrawalList: 'get-cash-withdrawal-list',
  };
  constructor() {}

  getCountByTypeAndStatus(type: string, status: string) {
    return this.http.get(
      `${this.BASE_URL}/transaction-meta?type=${type}&status=${status}`
    );
  }

  filterTransactions(value: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/transaction-filter`, value);
  }

  viewWalletList(data: Pagination = new Pagination()): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/get-wallet-transactions?take=${data.size}&sort=${data.sort}&skip=${data.skip}`
    );
  }

  filterWalletTransactionsList(queryParams: string): Observable<TransactionsResponse> {
    return this.http.get<TransactionsResponse>(`${this.BASE_URL}/transaction-filter?${queryParams}`);
  }

  viewWalletTransactionsList(
    data: Pagination = new Pagination()
  ): Observable<TransactionsResponse> {
    const uniqueStateUrl = 'get-wallet-transactions';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<TransactionsResponse>(
        `${this.BASE_URL}/get-wallet-transactions?take=${data.size}&sort=${data.sort}&skip=${data.skip}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  filterAccountTransactions(queryParams: string, accountId: string): Observable<WalletTransactionResponse> {
    return this.http.get<WalletTransactionResponse>(`${this.BASE_URL}/filter-transaction-by-account?account_id=${accountId}&${queryParams}`);
  }


  viewAccountTransactions(
    accountId: string,
    pagination: Pagination = new Pagination()
  ): Observable<WalletTransactionResponse> {
    return this.http.get<WalletTransactionResponse>(
      `${this.BASE_URL}/get-transactions-by-account?account_id=${accountId}&take=${pagination.size}&sort=${pagination.sort}&skip=${pagination.skip}`
    );
  }

  processDebitTransaction(debitPaylod: {
    status: 'denied' | 'completed';
    transactionId: string;
    accountId: string;
  }): Observable<unknown> {
    return this.http.post(`${this.WITHDRAWAL_URL}/process-withdrawal-request`, {
      status: debitPaylod.status,
      transaction_id: debitPaylod.transactionId,
      account_id: debitPaylod.accountId,
    });
  }
  reverseTransaction(transactionId:any): Observable<unknown> {
    return this.http.post(`${this.BASE_URL}/reverse-fund?transaction_id=${transactionId}`,null);
  }

  approveForWallet(payload: {
    status: 'denied' | 'approved' | 'pending' | 'withdrawn';
    request_id: string;
  }): Observable<unknown> {
    return this.http.post(
      `${this.WITHDRAWAL_URL}/process-cash-to-wallet-requests`,
      {
        status: payload.status,
        request_id: payload.request_id,
      }
    );
  }

  approveForBank(payload: {
    status: 'denied' | 'approved' | 'pending' | 'withdrawn';
    request_id: string;
  }): Observable<unknown> {
    return this.http.post(
      `${this.WITHDRAWAL_URL}/approve-cash-to-bank-account`,
      {
        status: payload.status,
        request_id: payload.request_id,
      }
    );
  }

  // localhost:1337/api/v1/withdrawal/get-cash-to-wallet-requests-details?request_id=c790389f-e89a-4506-9e4f-a46bd1bdb3b4
  getCashToWalletDetails(
    transactionId: string
  ): Observable<CashToWalletResponse> {
    return this.http.get<CashToWalletResponse>(
      `${this.WITHDRAWAL_URL}/get-cash-to-wallet-requests-details?request_id=${transactionId}`
    );
  }

  getBeneficiaryDetails(accountId: string): Observable<BeneficiaryDetails> {
    const uniqueStateUrl = URL_KEYS.TRANSACTIONS.GET_BENEFICIARY_DETAILS;
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<BeneficiaryDetails>(
        `${this.WITHDRAWAL_URL}/get-transfer-bank-account?account_id=${accountId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getTransactionDetails(
    transactionId: string
  ): Observable<TransactionDetailsResponse> {
    const uniqueStateUrl = 'get-transaction-details';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<TransactionDetailsResponse>(
        `${this.WITHDRAWAL_URL}/get-transaction-details?id=${transactionId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  // 
  filterTransactionList(queryParams: string): Observable<WalletTransactionDetailsResponse> {
    return this.http.get<WalletTransactionDetailsResponse>(
      `${this.WITHDRAWAL_URL}/cash-transaction-filter?${queryParams}`
    );
  }
  getCashTransactionList(
    page: Pagination
  ): Observable<WalletTransactionDetailsResponse> {
    this.loadingService.setLoading(
      this.transLoadState.getCashWithrawalList,
      true
    );

    const paginationInstance = new Pagination(page);
    const params = new HttpParams()
      .set('take', paginationInstance.size.toString())
      .set('sort', paginationInstance.sort)
      .set('skip', paginationInstance.skip.toString());

    return this.http
      .get<WalletTransactionDetailsResponse>(
        `${this.WITHDRAWAL_URL}/get-cash-withdrawal-requests`,
        { params }
      )
      .pipe(
        finalize(() =>
          this.loadingService.setLoading(
            this.transLoadState.getCashWithrawalList,
            false
          )
        )
      );
  }

  getProvidusSettlementAccountBalance(): Observable<ProvidusSettlementAcctBalResponse> {
    const uniqueStateUrl = 'get-providus-settlement-account-balance';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<ProvidusSettlementAcctBalResponse>(
        `${this.BASE_URL}/get-providus-settlement-account-balance`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getOptimusSettlementAccountBalance(): Observable<OptimusSettlementAcctBalResponse> {
    const uniqueStateUrl = 'get-optimus-account-balance';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<OptimusSettlementAcctBalResponse>(
        `${this.BASE_URL}/get-optimus-account-balance`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getGlobalPortfolioBalance(): Observable<ProvidusSettlementAcctBalResponse> {
    const uniqueStateUrl = 'get-global-portfolio-balance';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
    // TODO: Add Type definition for response
      .get<ProvidusSettlementAcctBalResponse>(
        `${this.PORTFOLIO_URL}/get-global-portfolio-cash-balance`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getGlobalWalletBalance(): Observable<ProvidusSettlementAcctBalResponse> {
    const uniqueStateUrl = 'get-global-wallet-balance';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<ProvidusSettlementAcctBalResponse>(
        `${this.ACCOUNT_URL}/get-global-wallet-balance`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}
